<template>
  <client-only>
    <transition v-if="!hidePromoBarBasedOnRoute" name="unfold">
      <lazy-cms-section
        v-if="showPromoBar && megaMenu?.promoBar"
        data-scroll-el="promo-bar"
        :section="sections.promoBar"
      />
    </transition>
  </client-only>
  <div
    v-if="header.transparent"
    class="pointer-events-none absolute-0 z-1"
    style="background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent); height: 8rem"
  />
  <div class="pointer-events-none relative z-1 flex between" :class="{ '<lg:mix-blend-difference': mobileBlendModeActive }">
    <!-- Logo -->
    <div class="pointer-within lg:hidden">
      <base-link
        class="i-block"
        :class="{ 'absolute z-1 p-4': header.transparent, 'p-4 pb-2': !header.transparent, '<lg:fixed': mobileBlendModeActive }"
        to="/"
      >
        <span class="sr-only">{{ $t.brand }} {{ $t.home }}</span>
        <vf-icon
          :class="{ 'c-white': header.transparent || mobileBlendModeActive }"
          height="23"
          :src="customOtwLogo ? `/img/logos/vans/otw.svg` : `/img/logos/vans/default.svg`"
          width="67"
        />
      </base-link>
    </div>
    <base-button
      v-if="mobileModalStyle"
      :aria-label="$t.close"
      class="pointer-within m-4 lg:hidden"
      @click="goBack"
    >
      <vf-icon name="close" size="md" />
    </base-button>
  </div>
  <div class="pointer-within sticky top-0 z-above-header" data-scroll-el="header">
    <header
      ref="headerRef"
      class="pointer-within inset-x-0 bottom-0 flex center <lg:fixed <lg:my-6 lg:transform"
      :class="{
        'lg:absolute lg:top-0 lg:bottom-a': header.transparent,
        '<lg:rounded-full': !header.expanded,
        'duration': !isSomethingElseSticky,
        '<lg:hidden': header.minimized,
      }"
      :style="`--un-translate-y: ${header.pageScrolledUp || !y ? '0' : '-100%'}`"
    >
      <div v-style:w="{ sm: '21.563rem', lg: '100%' }" class="flex center rounded">
        <div
          class="flex center p-1 ease-out duration lg:grid lg:cols-[1fr_auto_1fr] lg:w-full lg:px-3 lg:py-2 <lg:gap-5 <lg:b <lg:rounded-$rounded <lg:bg-brand-2 <lg:op-$opacity <lg:shadow-$shadow <lg:backdrop-blur"
          :class="[{ grow: header.expanded }, header.transparent && !isPassedTransparencyThreshold ? 'lg:bg-transparent' : 'lg:bg-white']"
          style="
            border-color: #D9D9D9;
             --rounded: 0.5rem;
            --shadow: inset 0 1px 1px 0 rgba(188, 187, 187, 0.1), 0 4px 10px 0 rgba(23, 26, 28, 0.2);
            --opacity: 0.96
          "
        >
          <base-button
            :aria-label="$t.mobileNavigationExpand"
            class="hidden p-$p"
            :class="{ 'c-white': header.transparent && !isPassedTransparencyThreshold, '<lg:block': !header.expanded }"
            style="--p:  0.625rem"
            @click="openMobileNavigation"
          >
            <vf-icon name="dots" :size="{ sm: 'md', lg: 'lg' }" />
          </base-button>

          <!-- Menu -->
          <base-button
            :aria-label="$t.menuIconLabel"
            class="group lg:mr-a"
            :class="{ '<lg:hidden': !header.expanded }"
            @click="openMegaMenu"
          >
            <span
              class="block rounded-sm p-$p duration lg:m-1 lg:p-1"
              :class="{
                'lg:c-white lg:group-hover:bg-brand-1/90': header.transparent && !isPassedTransparencyThreshold,
                'lg:group-hover:bg-brand-2': !header.transparent
                  || (header.transparent && isPassedTransparencyThreshold),
              }"
              style="--p:  0.625rem"
            >
              <vf-icon name="menu" :size="{ sm: 'md', lg: 'lg' }" />
            </span>
          </base-button>

          <div class="flex grow center <lg:hidden">
            <!-- Logo -->
            <base-link class="p-2 duration @hactive:-rotate-3" to="/">
              <span class="sr-only">{{ $t.brand }} {{ $t.home }}</span>
              <vf-icon
                :class="{ 'c-white': header.transparent && !isPassedTransparencyThreshold }"
                height="23"
                :src="customOtwLogo ? `/img/logos/vans/otw.svg` : `/img/logos/vans/default.svg`"
                width="67"
              />
            </base-link>
          </div>

          <section class="flex lg:ml-a <lg:gap-5" :class="{ '<lg:hidden': !header.expanded }" data-test-id="menu-section">
            <vf-button
              v-if="showClearSessionButton && buyInStore.storeInfo?.storeId"
              size="tiny"
              variant="primary"
              @click="buyInStore.clearSession"
            >
              {{ $t.clearSession }}
            </vf-button>
            <!-- Account -->
            <base-button
              :aria-label="$t.myAccount"
              class="group"
              @click="openAccountPanel"
            >
              <span
                class="block rounded-sm p-$p duration lg:m-1 lg:p-1"
                :class="{
                  'lg:c-white lg:group-hover:bg-brand-1/90': header.transparent && !isPassedTransparencyThreshold,
                  'lg:group-hover:bg-brand-2': !header.transparent
                    || (header.transparent && isPassedTransparencyThreshold),
                }"
                style="--p:  0.625rem"
              >
                <vf-icon class="pointer-events-none" name="account" :size="{ sm: 'md', lg: 'lg' }" />
              </span>
            </base-button>

            <!-- AI Button. The click event is injected through Bloomreach -->
            <base-button
              v-style:display="aiDisplayStyle"
              id="ai-button"
              class="group"
              @click="$gtm.push('topNav.onClickUtilityLink', 'AI Shopping Assistant')"
            >
              <span
                class="block rounded-sm p-$p duration lg:m-1 lg:p-1"
                :class="{
                  'lg:c-white lg:group-hover:bg-brand-1/90': header.transparent
                    && !isPassedTransparencyThreshold,
                  'lg:group-hover:bg-brand-2': !header.transparent
                    || (header.transparent && isPassedTransparencyThreshold),
                }"
                style="--p:  0.625rem"
              >
                <vf-icon name="ai" :size="{ sm: 'md', lg: 'lg' }" />
              </span>
            </base-button>

            <!-- Search -->
            <base-button
              :aria-haspopup="$viewport.lg ? 'menu' : undefined"
              :aria-label="$t.searchIconLabel"
              class="group"
              @click="openSearchPanel"
            >
              <span
                class="block rounded-sm p-$p duration lg:m-1 lg:p-1"
                :class="{
                  'lg:c-white lg:group-hover:bg-brand-1/90': header.transparent
                    && !isPassedTransparencyThreshold,
                  'lg:group-hover:bg-brand-2': !header.transparent
                    || (header.transparent && isPassedTransparencyThreshold),
                }"
                style="--p:  0.625rem"
              >
                <vf-icon name="search" :size="{ sm: 'md', lg: 'lg' }" />
              </span>
            </base-button>

            <!-- Cart -->
            <base-link
              :aria-label="cart?.totalItems
                ? replaceAll($pluralize($t.cartWithItems, cart?.totalItems), { totalItems: cart?.totalItems })
                : $t.cart"
              class="group"
              to="/cart"
              @click="$gtm.push('topNav.onClickUtilityLink', 'Cart')"
            >
              <span
                class="relative block rounded-sm p-$p duration lg:m-1 lg:p-1"
                :class="{
                  'lg:c-white lg:group-hover:bg-brand-1/90': header.transparent && !isPassedTransparencyThreshold,
                  'lg:group-hover:bg-brand-2': !header.transparent
                    || (header.transparent && isPassedTransparencyThreshold),
                }"
                style="--p:  0.625rem"
              >
                <vf-icon name="bag" :size="{ sm: 'md', lg: 'lg' }" />
                <client-only>
                  <span
                    v-if="cart?.totalItems"
                    aria-hidden="true"
                    class="absolute right-0 top-0 m-2 h-3 w-3 flex select-none center rounded-full bg-brand-3 text-micro c-white ring ring-white lg:m-1 lg:h-4 lg:w-4 lg:text-xs"
                    style="line-height: 1"
                  >
                    {{ cart.totalItems }}
                  </span>
                </client-only>
              </span>
            </base-link>
          </section>
        </div>
        <div
          v-if="!header.minimized"
          ref="menuTeleportRef"
          class="flex duration lg:hidden"
          :class="{ grow: !header.expanded }"
          data-teleport="menu-buttons"
        />
      </div>
    </header>
  </div>

  <!-- Rendering Mega Menu to SSR for Search Engines -->
  <base-lazy-hydrate when="never">
    <vf-mega-menu class="hidden" :items="megaMenu" :links />
  </base-lazy-hydrate>

  <panel-mega-menu
    class-content="bg-transparent backdrop-blur"
    hide-backdrop
    :position="{ sm: 'bottom', lg: 'left' }"
    size="full"
    @sign-in="DialogSignIn.open()"
  />
  <panel-account size="full" />
  <panel-search class-content="lg:w-full" size="full" />

  <!-- Custom Close button for the mega menu -->
  <!-- Needs to be added separately due to a issue of using backdrop filter with nested fixed elements  -->
  <vf-button
    :aria-label="$t.close"
    class="pointer-events-auto fixed bottom-0 right-0 m-6 rounded-sm p-3 duration lg:hidden"
    :class="{ 'op-0 -mb-6': !megaMenuOpened }"
    data-test-id="vf-panel-close"
    style="z-index: 10001"
    variant="primary-inverse"
    @click="PanelMegaMenu.close()"
  >
    <vf-icon name="close" size="lg" style="margin: -0.355rem" />
  </vf-button>
</template>

<script lang="ts" setup>
import type { FormLocation } from '#root/types/gtm'
import { EnrollmentState } from '#root/enums/profile'
import { HeaderContextKey } from '#commerce/components/vf/header/context'

const { isSomethingElseSticky, links, megaMenu, sections, showPromoBar } = inject(HeaderContextKey)!
const { showClearSessionButton } = useAppConfig().checkout.buyInStore
const auth = useAuthStore()
const buyInStore = useBuyInStoreStore()
const cart = useCartStore()
const { DialogSignIn } = useDialogsStore()
const {
  disableAiNavItemOnRoutes,
  disablePromoBarOnRoutes,
  headerMobilePromoBlendModeRoutes,
  otwStyleRoutes
} = useFeatureFlags()
const header = useHeaderStore()
const { $feature, $gtm, $viewport } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const { y } = useScroll(document, { throttle: 200 })
const menuTeleportRef = useState<HTMLElement>('menuTeleportRef')
const { enrollmentState } = storeToRefs(useUserStore())
const windowSize = useWindowSize()

const PanelAccount = createPanel('account')
const PanelMegaMenu = createPanel('mega-menu')
const PanelSearch = createPanel('search')

const headerRef = toRef(header.refs, 'header')
const megaMenuOpened = ref(false)

const aiDisplayStyle = computed(
  () => Object.entries(disableAiNavItemOnRoutes || { sm: ['/**'] }).reduce((acc, [key, patternsToDisable]) => {
    acc[key] = !patternsToDisable.some((patternToDisable) => convertPatternToRegex(patternToDisable).test(route.path)) ? 'inline-flex' : 'none'
    return acc
  }, {})
)

const customOtwLogo = computed(
  () => otwStyleRoutes.some((pattern) => convertPatternToRegex(pattern).test(route.fullPath))
)

const hidePromoBarBasedOnRoute = computed(
  () => disablePromoBarOnRoutes.some((pattern) => convertPatternToRegex(pattern).test(route.path))
)

const isPassedTransparencyThreshold = computed(() => y.value > (windowSize.height.value - header.height.header))

const mobileBlendModeActive = computed(
  () => headerMobilePromoBlendModeRoutes.some((pattern) => convertPatternToRegex(pattern).test(route.path))
)

const mobileModalStyle = computed(() => ['/cart', '/customizer'].some((path) => route.path.includes(path)))

const productId = computed(() => getUrlId(route.path, 'Product'))

const openAccountPanel = () => {
  if (auth.loggedIn) {
    $gtm.push('topNav.onClickUtilityLink', 'My account')
    return PanelAccount.open()
  }

  $gtm.push('topNav.onClickUtilityLink', 'Sign In')
  DialogSignIn.open({ formLocation: 'modal:single:body' }).then(() => {
    if ($feature.allowSimplifiedEnrollment) {
      const formLocation: FormLocation = 'modal:single:none'
      switch (enrollmentState.value) {
        case EnrollmentState.COMPLETE_PROFILE_2_MOBILE:
          $gtm.push('authForm.onLoyaltyStep', 'Setup:Step1:phoneNumber', 'Closed', formLocation)
          break
        case EnrollmentState.COMPLETE_PROFILE_3_PROFILE:
          $gtm.push('authForm.onLoyaltyStep', 'Setup:Step2:nameZipCodeBirthday', 'Closed', formLocation)
          break
        case EnrollmentState.COMPLETE_PROFILE_4_INTERESTS:
          $gtm.push('authForm.onLoyaltyStep', 'Setup:Step3:interests', 'Closed', formLocation)
          break
      }
    }
  })
}

const openMegaMenu = () => {
  // Dialog/Panels promises are resolved when they close
  PanelMegaMenu.open({ items: megaMenu, links: links.value }).then((backdropClicked) => {
    megaMenuOpened.value = false
    if (backdropClicked)
      $gtm.push('topNav.onClickHamburgerMenu', false)
  })
  megaMenuOpened.value = true
  $gtm.push('topNav.onClickHamburgerMenu', true)
}

const openSearchPanel = () => {
  $gtm.push('topNav.onClickUtilityLink', 'Search')
  PanelSearch.open()
}

const openMobileNavigation = () => {
  header.expanded = true
  if (productId.value) $gtm.push('pdpPage.onMobileNavigationInteraction', productId.value, true)
}

const goBack = () => {
  window.history.state.back ? router.back() : router.push('/')
}

watch(mobileModalStyle, () => {
  header.minimized = mobileModalStyle.value
}, { immediate: true })
</script>
